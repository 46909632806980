import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "p-5" }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("a-card", { attrs: { "body-style": { padding: 0 } } }, [_c("div", { staticClass: "p-4 card-container" }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "default-active-first-option": "", "label": "Version", "placeholder": "Select Version", "reference": "web-analytics.common.profit-open-version", "source": "version" }, on: { "change": function($event) {
    return _vm.storeValue("formDownload", "version", $event);
  } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "default-active-first-option": "", "label": "Fin Year (Cal)", "placeholder": "Select Fin Year", "data-source": _vm.formDownloadFinYear, "source": "finYear", "source-label": _vm.parseFinYear }, on: { "change": function($event) {
    return _vm.storeValue("formDownload", "finYear", $event);
  } } })], 1)], 1), _c("a-row", { staticClass: "mt-3", attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "default-active-first-option": "", "label": "Cluster Group / Channel", "placeholder": "Select Cluster Group", "reference": "web-analytics.common.profit-cluster-group", "source": "clusterGroup" }, on: { "change": function($event) {
    return _vm.storeValue("formDownload", "clusterGroup", $event);
  } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "data-source": _vm.formDownloadClusters, "default-active-first-option": "", "label": "Cluster/ Channel", "placeholder": "Select Cluster", "source": "cluster" }, on: { "change": function($event) {
    return _vm.storeValue("formDownload", "cluster", $event);
  } } })], 1)], 1)], 1), _c("a-divider", { staticClass: "m-0" }), _c("div", { staticClass: "d-flex justify-content-end p-4" }, [_c("a-button", { attrs: { "loading": _vm.isDownloading, "type": "primary" }, on: { "click": _vm.onDownloadClick } }, [_vm._v(" Download ")])], 1)], 1)], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("a-card", { attrs: { "body-style": { padding: 0 } } }, [_c("div", { staticClass: "p-4 card-container" }, [_c("a-row", { attrs: { "gutter": [24, 16] } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "default-active-first-option": "", "label": "Version", "placeholder": "Select Version", "reference": "web-analytics.common.profit-open-version", "source": "version" }, on: { "change": _vm.handleChangeVersion } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "default-active-first-option": "", "label": "Fin Year (Cal)", "placeholder": "Select Fin Year", "data-source": _vm.formImportFinYear, "source": "finYear", "source-label": _vm.parseFinYear, "disabled": _vm.disableFinYear }, on: { "change": function($event) {
    return _vm.storeValue("formImport", "finYear", $event);
  } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "default-active-first-option": "", "label": "Cluster Group / Channel", "placeholder": "Select Cluster Group", "reference": "web-analytics.common.profit-cluster-group", "source": "clusterGroup" }, on: { "change": function($event) {
    return _vm.storeValue("formImport", "clusterGroup", $event);
  } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "data-source": _vm.formImportClusters, "default-active-first-option": "", "label": "Cluster/ Channel", "placeholder": "Select Cluster", "source": "cluster" }, on: { "change": function($event) {
    return _vm.storeValue("formImport", "cluster", $event);
  } } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("text-input", { attrs: { "disabled": "", "label": "File name", "value": _vm.fileName } })], 1), _c("a-col", { attrs: { "span": 16 } }, [_c("text-input", { attrs: { "disabled": "", "label": "Location", "value": _vm.fileLocation } })], 1)], 1)], 1), _c("a-divider", { staticClass: "m-0" }), _c("div", { staticClass: "d-flex justify-content-end p-4" }, [_c("a-button", { attrs: { "loading": _vm.isImporting, "type": "primary" }, on: { "click": _vm.onImportClick } }, [_vm._v(" Import ")])], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns$1 = [];
var UploadAccessDatabase_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".card-container[data-v-1dfd4f4d]{min-height:239px}\n")();
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const clusterApiUrl = `${apiUrl$1}/web-analytics/common/profit-cluster`;
const finYearApiUrl = `${apiUrl$1}/web-analytics/common/profit-open-fin-year`;
const downloadBaseDataApiUrl = `${apiUrl$1}/web-analytics/profit-download-base-data`;
const importManualForecastApiUrl = `${apiUrl$1}/web-analytics/profit-import-manual-forecasts`;
const __vue2_script$1 = {
  name: "UploadAccessDatabase",
  data() {
    return {
      fileName: void 0,
      formDownload: {
        cluster: void 0,
        clusterGroup: void 0,
        finYear: void 0,
        version: void 0
      },
      formDownloadClusters: [],
      formDownloadFinYear: [],
      formImport: {
        cluster: void 0,
        clusterGroup: void 0,
        finYear: void 0,
        version: void 0
      },
      formImportClusters: [],
      formImportFinYear: [],
      isDownloading: false,
      isImporting: false,
      fileLocation: void 0,
      disableFinYear: false
    };
  },
  watch: {
    "formDownload.clusterGroup": async function(newVal) {
      try {
        const res = await this.axios.get(clusterApiUrl, {
          params: { clusterGroup: newVal }
        });
        this.formDownloadClusters = res.data;
      } catch (error) {
        this.$notification(error.message);
      }
    },
    "formDownload.version": async function(newVal) {
      try {
        const res = await this.axios.get(finYearApiUrl, {
          params: { version: newVal }
        });
        this.formDownloadFinYear = res.data;
      } catch (error) {
        this.$notification(error.message);
      }
    },
    "formImport.cluster": async function(newVal) {
      try {
        const res = await this.axios.get(importManualForecastApiUrl, {
          params: {
            clusterGroup: this.formImport.clusterGroup,
            cluster: newVal
          }
        });
        this.fileLocation = res.data.fileLocation;
        this.fileName = res.data.fileName;
      } catch (error) {
        this.$notification(error.message);
      }
    },
    "formImport.clusterGroup": async function(newVal) {
      try {
        const res = await this.axios.get(clusterApiUrl, {
          params: { clusterGroup: newVal }
        });
        this.formImportClusters = res.data;
      } catch (error) {
        this.$notification(error.message);
      }
    },
    "formImport.version": async function(newVal) {
      try {
        const res = await this.axios.get(finYearApiUrl, {
          params: { version: newVal }
        });
        this.formImportFinYear = res.data;
      } catch (error) {
        this.$notification(error.message);
      }
    }
  },
  methods: {
    parseFinYear(option) {
      return option.finYear === 0 ? "All" : option.finYear;
    },
    storeValue(form, key, value) {
      this[form][key] = value;
    },
    handleChangeVersion(value) {
      this.storeValue("formImport", "version", value);
      if (value === "FORECASTLE") {
        this.disableFinYear = true;
      } else {
        this.disableFinYear = false;
      }
    },
    async onDownloadClick() {
      this.isDownloading = true;
      try {
        const res = await this.axios.post(downloadBaseDataApiUrl, this.formDownload);
        this.$notification.success({ message: res.data.message });
      } catch (error) {
        this.$notification.error({ message: error.message });
      } finally {
        this.isDownloading = false;
      }
    },
    async onImportClick() {
      this.isImporting = true;
      try {
        const res = await this.axios.post(importManualForecastApiUrl, this.formImport);
        if (res.data.message) {
          this.$notification.success({ message: res.data.message });
        } else {
          this.$notification.success({ message: "Success" });
        }
      } catch (error) {
        this.$notification.error(error.message);
      } finally {
        this.isImporting = false;
      }
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "1dfd4f4d", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var UploadAccessDatabase = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.menuItems } }), _c("resource", { attrs: { "name": "web-analytics.common.profit-open-version", "api-url": _vm.apiUrl, "resource-id-name": "version" } }), _c("resource", { attrs: { "name": "web-analytics.common.profit-open-fin-year", "resource-id-name": "finYear", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.common.profit-cluster-group", "resource-id-name": "clusterGroup", "api-url": _vm.apiUrl } }), _c("upload-access-database")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    UploadAccessDatabase
  },
  data() {
    return {
      apiUrl,
      menuItems: [
        {
          key: "profitability",
          title: "Profitability",
          path: ""
        },
        {
          key: "upload-access-database",
          title: "Upload/Download Manual Forecast",
          path: "/web-analytics/upload-access-database"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
